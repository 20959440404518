import AdminItemRow from '@components/admin/components/AdminItemRow.tsx';
import FormSection from '@components/admin/components/form/FormSection.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import { IClientDocType } from '@shared/helpers/converters/doctype.ts';
import { extendedSearch, globalFuseOptions } from '@shared/helpers/helpers.ts';
import { useModal } from '@shared/hooks/useModal.tsx';
import { useNotification } from '@shared/hooks/useNotificationBar.tsx';
import { adminSlice, adminValidTopologyTypesSelector, deleteDocTypes } from '@shared/store/adminSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import p from '@shared/styles/component/admin/admin-pages/admin-page.module.scss';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

const fuseOptions = {
  ...globalFuseOptions,
  keys: ['name'],
};
const AdminInboxesDoctypes: React.FC = () => {
  const inboxDocTypes = useSelector((state) => state.admin.inboxDocTypes);
  const activeDocTypeList = useSelector((state) => state.admin.activeDocTypeList);
  const validTopologyTypes = useSelector(adminValidTopologyTypesSelector);

  const dispatch = useDispatch();
  const { showDialog } = useModal();
  const { t } = useTranslation();
  const { inboxId } = useParams();
  const { showNotification } = useNotification();

  const [fuseData, setFuseData] = useState([]);
  const [searchResults, setSearchResults] = useState<IClientDocType[]>(null);
  const [selectedDocTypes, setSelectedDocTypes] = useState<string[]>([]);

  const navigate = useNavigate();

  const fuse = new Fuse(fuseData, fuseOptions);

  const sortList = (list: IClientDocType[]) => {
    return list.sort((a, b) => {
      if (b.id === '@PB_NOTYPE') return -1;
      if (a.id === '@PB_NOTYPE') return 1;
      return a.name.localeCompare(b.name);
    });
  };
  const handleMatches = (input) => {
    const list = sortList(extendedSearch(input, fuse));
    setSearchResults(list);
  };

  const handleSearchInput = (value) => {
    if (value === '') {
      setSearchResults(sortList([...inboxDocTypes]));
    } else {
      handleMatches(value);
    }
  };

  const handleDelete = (setting: IClientDocType) => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => deleteDocTypes(inboxId, [setting.id])}
        text={t('admin:inboxes.sections.docTypeDelete')}
      />,
    );
  };

  const handleMultiDelete = () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          deleteDocTypes(inboxId, selectedDocTypes).then(() => setSelectedDocTypes([]));
        }}
        text={t('admin:inboxes.sections.docTypeMultiDelete')}
      />,
    );
  };
  useEffect(() => {
    return () => {
      dispatch(adminSlice.actions.setNewTempSubTypes(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (inboxDocTypes) {
      setSearchResults(sortList([...inboxDocTypes]));
      setFuseData(inboxDocTypes);
    }
  }, [inboxDocTypes]);

  useEffect(() => {
    setSelectedDocTypes(activeDocTypeList ?? []);
  }, [activeDocTypeList]);

  return (
    <div className={clsx(s.form_body, s.form_body_scroll)}>
      <div className={p.body_header}>
        <h2>{t('admin:inboxes.docTypes')}</h2>
      </div>
      <p className={p.body_description}>{t('admin:inboxes.docTypesDescription')}</p>
      {validTopologyTypes.map((types) => {
        let list = searchResults?.filter((e) => e.topologyType === types.value) ?? [];
        if (types.value === 'bundle') {
          list =
            searchResults?.filter(
              (e) =>
                e.topologyType === 'bundle' || (e.topologyType !== 'document' && e.topologyType !== 'mail'),
            ) ?? [];
        }
        return (
          <FormSection
            key={types.value}
            noStyle
            scroll
            title={`${types.label} types`}
            add={{
              testId: `doctype-add-${types.label}`,
              onClick: () => navigate(`new?topologyType=${types.value}`),
              label: t('admin:inboxes.sections.addNew'),
            }}
            search={{
              onChange: (e) => handleSearchInput(e),
              placeholder: t('admin:inboxes.sections.docTypeSearch'),
            }}
            select={{
              handleEdit: () => {
                navigate(`multi?topologyType=${types.value}&ids=${selectedDocTypes.join(',')}`);
              },
              handleDelete: handleMultiDelete,
              setSelectedValues: (val) => {
                setSelectedDocTypes(val);
              },
              selectedValues: selectedDocTypes,
              allValues: [...(list ?? [])].map((e) => e.id).filter((e) => e !== '@PB_NOTYPE'),
            }}
            copy={{
              copyValues: list?.map((dt) => dt.id) ?? [],
            }}
          >
            <div className={clsx(s.row_list)}>
              {list?.map((setting, index) => {
                const isChecked = selectedDocTypes.findIndex((e) => e === setting.id) !== -1;
                return (
                  <AdminItemRow
                    animationSettings={{
                      enabled: true,
                      delay: 50 + index * 25,
                    }}
                    isChecked={isChecked}
                    setIsChecked={
                      setting.id !== '@PB_NOTYPE'
                        ? (val) => {
                            if (val) {
                              setSelectedDocTypes((cur) => {
                                return [...cur, setting.id];
                              });
                            } else {
                              setSelectedDocTypes((cur) => {
                                return [...cur].filter((e) => e !== setting.id);
                              });
                            }
                          }
                        : null
                    }
                    handleDelete={setting.id !== '@PB_NOTYPE' ? () => handleDelete(setting) : null}
                    handleNav={() => {
                      navigate(setting.id);
                    }}
                    handleCopy={() => {
                      navigator.clipboard.writeText(setting.id);
                      showNotification(t('home:notifications.copied'), 'success');
                    }}
                    key={`${setting.name}doctype${setting.id}`}
                    docType={setting}
                    title={setting.name}
                  />
                );
              })}
              {list?.length === 0 && (
                <div key={`${inboxId}doctype`} className={s.no_results}>
                  {t('admin:inboxes.sections.noDocTypeFound')}
                </div>
              )}
            </div>
          </FormSection>
        );
      })}
    </div>
  );
};

export default AdminInboxesDoctypes;
