import { DropdownOption } from '@components/shared/dropdown/StyledSelect';
import { CombinedState, PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { IClientFieldType } from '@shared/helpers/converters/fieldtype.ts';
import {
  camelToSnakeCase,
  compareObjects,
  getUserToken,
  normalizeEntity,
  sleep,
  snakeToCamelCase,
  unNormalizeEntity,
  uuid4hex,
} from '@shared/helpers/helpers';
import {
  CopyStructure,
  DocTypeCategory,
  DocumentApprovalCheck,
  DocumentDetails,
  DocumentEntity,
  DocumentLocker,
  DocumentNote,
  DocumentPart,
  PageTokens,
  SearchResult,
  SearchResultsRaw,
  UnmappedDocumentMetadata,
} from '@shared/models/document';
import { DocTypeSummary } from '@shared/models/inbox';
import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { DatabaseReference, child, onDisconnect, runTransaction, update } from 'firebase/database';
import {
  Unsubscribe,
  collection,
  doc,
  documentId,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { cloneDeep, isEqual } from 'lodash';
import snakecaseKeys from 'snakecase-keys';
import { elasticSortMap, inboxSlice } from './inboxSlice';
import { setSortedDocumentEntities } from './labelerSlice';
import { settingsSlice } from './settingsSlice';
import { api, db } from './setup/firebase-setup';
import { AppThunk, RootState } from './store';
import { subsSlice } from './subsSlice';

interface DocumentState {
  allowedEntityTypes?: IClientFieldType[];
  docTypeCategories?: DocTypeCategory[];
  activeDocId?: string;
  activeDocument?: DocumentDetails;
  activeDocumentSnapshot?: any;
  nextDocumentId?: string;
  prevDocumentId?: string;
  documentJSON?: PageTokens[];
  copyStructure: CopyStructure;
  lockedId?: string;
  lockListener?: DatabaseReference;
  lockedList?: Record<string, DocumentLocker>;
  selectedCopyId?: string;
  deletingCopyId?: string;
  isLabelerTourActive: boolean;
  doesNotExist?: Partial<DocumentDetails>;
  isPatching: boolean;
  isImageLoading: boolean;
  isThumbsLoading: boolean;
  isViewerLoaded: boolean;
  isJsonLoading: boolean;
  isProcessing: boolean;
  isCreatingCopy: boolean;
  selectedDocTypeOption?: DocTypeSummary;
  masterDataResults?: SearchResult[];
  masterDataAvailableTypes?: DropdownOption[];
  masterDataImportStatus?: 'importing' | 'error' | 'idle';
  isMasterDataSearchActive?: boolean;
  masterDataStatus?: 'searching' | 'error' | 'no-results' | 'idle';
  pageImagesMap?: Record<string, Record<string, { imageUrl?: string; thumbUrl?: string }>>;
}

const initialState: DocumentState = {
  doesNotExist: null,
  isPatching: false,
  isImageLoading: false,
  isThumbsLoading: false,
  isViewerLoaded: false,
  isJsonLoading: false,
  isCreatingCopy: false,
  isLabelerTourActive: false,
  isProcessing: false,
  copyStructure: {},
  masterDataAvailableTypes: [],
  pageImagesMap: {},
};

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setActiveDocument: (state, action: PayloadAction<DocumentDetails>) => {
      state.activeDocument = action.payload;
    },
    setActiveDocId: (state, action: PayloadAction<string>) => {
      state.activeDocId = action.payload;
    },
    setActiveDocumentSnapshot: (state, action: PayloadAction<any>) => {
      state.activeDocumentSnapshot = action.payload;
    },
    setIsPatching: (state, action: PayloadAction<boolean>) => {
      state.isPatching = action.payload;
    },

    setDocumentMapImage: (
      state,
      action: PayloadAction<{ docId: string; pageNo: number; imageUrl?: string; thumbUrl?: string }>,
    ) => {
      try {
        const { docId, pageNo, imageUrl, thumbUrl } = action.payload;
        const clone = cloneDeep(state.pageImagesMap);
        // Check if imageUrl or thumbUrl are null to remove the entry
        if (imageUrl == null && thumbUrl == null) {
          delete clone[docId][pageNo];
          if (Object.keys(clone[docId]).length === 0) {
            delete clone[docId];
          }
        } else {
          if (!clone[docId]) {
            clone[docId] = {}; // Initialize docId if it doesn't exist
          }
          if (!clone[docId][pageNo]) {
            clone[docId][pageNo] = {}; // Initialize pageNo if it doesn't exist
          }
          // Update imageUrl and/or thumbUrl
          if (imageUrl) {
            clone[docId][pageNo] = {
              ...clone[docId][pageNo],
              imageUrl,
            };
          }
          if (thumbUrl) {
            clone[docId][pageNo] = {
              ...clone[docId][pageNo],
              thumbUrl,
            };
          }
        }

        state.pageImagesMap = clone;
      } catch (e) {
        console.log(e);
      }
    },
    setIsImageLoading: (state, action: PayloadAction<boolean>) => {
      state.isImageLoading = action.payload;
    },
    setIsThumbsLoading: (state, action: PayloadAction<boolean>) => {
      state.isThumbsLoading = action.payload;
    },
    setIsViewerLoaded: (state, action: PayloadAction<boolean>) => {
      state.isViewerLoaded = action.payload;
    },
    setIsProcessing: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },

    setDoesNotExist: (state, action: PayloadAction<Partial<DocumentDetails>>) => {
      state.doesNotExist = action.payload;
    },

    setIsJsonLoading: (state, action: PayloadAction<boolean>) => {
      state.isJsonLoading = action.payload;
    },
    setIsCreatingCopy: (state, action: PayloadAction<boolean>) => {
      state.isCreatingCopy = action.payload;
    },
    setDeletingCopyId: (state, action: PayloadAction<string>) => {
      state.deletingCopyId = action.payload;
    },
    setAllowedEntityTypes: (state, action: PayloadAction<IClientFieldType[]>) => {
      state.allowedEntityTypes = action.payload;
    },
    setDocTypeCategories: (state, action: PayloadAction<DocTypeCategory[]>) => {
      state.docTypeCategories = action.payload;
    },
    setNextDocumentId: (state, action: PayloadAction<string>) => {
      state.nextDocumentId = action.payload;
    },
    setPrevDocumentId: (state, action: PayloadAction<string>) => {
      state.prevDocumentId = action.payload;
    },
    setDocumentJSON: (state, action: PayloadAction<PageTokens[]>) => {
      state.documentJSON = action.payload;
    },
    setIsLabelerTourActive: (state, action: PayloadAction<boolean>) => {
      state.isLabelerTourActive = action.payload;
    },
    setLockedId: (state, action: PayloadAction<string>) => {
      state.lockedId = action.payload;
    },
    setLockListener: (state, action: PayloadAction<DatabaseReference>) => {
      state.lockListener = action.payload;
    },
    setLockedList: (state, action: PayloadAction<Record<string, DocumentLocker>>) => {
      state.lockedList = action.payload;
    },
    setCopyStructure: (state, action: PayloadAction<CopyStructure>) => {
      state.copyStructure = action.payload;
    },
    setSelectedCopyId: (state, action: PayloadAction<string>) => {
      state.selectedCopyId = action.payload;
    },

    setSelectedDocTypeOption: (state, action: PayloadAction<DocTypeSummary>) => {
      state.selectedDocTypeOption = action.payload;
    },
    setIsMasterDataSearchActive: (state, action: PayloadAction<boolean>) => {
      state.isMasterDataSearchActive = action.payload;
    },
    setMasterDataAvailableTypes: (state, action: PayloadAction<DropdownOption[]>) => {
      state.masterDataAvailableTypes = action.payload;
    },
    setMasterDataStatus: (state, action: PayloadAction<typeof state.masterDataStatus>) => {
      state.masterDataStatus = action.payload;
    },
    setMasterDataResults: (state, action: PayloadAction<SearchResult[]>) => {
      state.masterDataResults = action.payload;
    },
    setMasterDataImportStatus: (state, action: PayloadAction<'importing' | 'error' | 'idle'>) => {
      state.masterDataImportStatus = action.payload;
    },
  },
});
let unsubDocument: Unsubscribe;
let unsubCopy: Unsubscribe;

const isPatchingSelector = (state: RootState) => state.document.isPatching;
const isDocumentLoadedSelector = (state: RootState) => state.document.activeDocument;
const isProcessingSelector = (state: RootState) => state.document.isProcessing;
const lockedIdSelector = (state: RootState) => state.document.lockedId;
const documentEntitiesSelector = (state: RootState) => state.labeler.documentEntities;
const copyStructureSelector = (state: RootState) => state.document.copyStructure;
const userSelector = (state: RootState) => state.user.userAccount;

export const isInteractiveSelector = createSelector(
  [
    isPatchingSelector,
    isDocumentLoadedSelector,
    isProcessingSelector,
    lockedIdSelector,
    documentEntitiesSelector,
    userSelector,
  ],
  (isPatching, document, isProcessing, lockedId, documentEntities, userAccount) => {
    // Return false if there's any entity with isPending status
    if (documentEntities?.some((e) => e.isPending)) {
      return false;
    }
    if (userAccount.isHidden) {
      if (!isPatching && !isProcessing && document != null) {
        return document?.nMutations > 0 || true;
      }
    } else {
      if (!isPatching && !isProcessing && lockedId && document != null) {
        if (document.parentDocId) {
          return lockedId === document.parentDocId || lockedId === document.id;
        }
        return document?.nMutations > 0 || true;
      }
    }

    return false;
  },
);

export const finalDocInStructureSelector = createSelector([copyStructureSelector], (copyStructure) => {
  if (!copyStructure) return false;
  let goNext = true;
  if (copyStructure.copyList) {
    const totalDocs = copyStructure.copyList.length + 1;
    let docsWithAction = 0;
    if (copyStructure.originalDoc.action) docsWithAction++;
    copyStructure?.copyList?.forEach((copy) => {
      if (copy.action) {
        docsWithAction++;
      }
    });
    if (totalDocs - 1 !== docsWithAction) goNext = false;
  }
  return goNext;
});
export const isMutationSelector = createSelector(
  [copyStructureSelector, isDocumentLoadedSelector],
  (copyStructure, activeDocument) => {
    return copyStructure?.originalDoc?.id !== activeDocument?.id;
  },
);
export const pageIndexToPageNoSelector = createSelector(
  [(state: RootState) => state.document.activeDocument],
  (activeDocument) => {
    let map = [];
    if (activeDocument?.topology?.parts?.[0].pages[0].bundlePageNo) {
      activeDocument.topology.parts
        .filter((pt) => !pt.archived)
        .forEach((e) => {
          map = [...map, ...e.pages.filter((p) => !p.archived).map((e) => e.bundlePageNo)];
        });
    } else if (activeDocument?.dimensions) {
      activeDocument.dimensions.forEach((_, i) => {
        map = [...map, i + 1];
      });
    }
    return map;
  },
);

const processDocument = (
  metadata: any,
  docId: string,
  document: DocumentDetails,
  getState: () => CombinedState<RootState>,
) => {
  if (metadata) {
    document.metadata = {
      provider: [],
      ...(metadata.table && {
        table: {
          tableId: metadata.table._table_id,
          versionId: metadata.table._version_id,
        },
      }),
    };

    if (metadata.provider) {
      document.metadata.provider = Object.entries(metadata.provider).map(
        ([type, value]) => ({ type, value }) as UnmappedDocumentMetadata,
      );
    }
  }

  document.id = docId;

  // Return early if dimensions are not present
  if (document.dimensions == null || document.dimensions.length === 0) return document;

  const activeDocument = getState().document.activeDocument;

  // Function to process entities
  const processEntities = (entities: DocumentEntity[]) =>
    entities.map((item) => {
      if (!item.uuid) {
        item.uuid = uuid4hex();
      }
      return unNormalizeEntity(item, document);
    });

  if (!activeDocument) {
    if (document.entities && document.entities.length > 0) {
      document.entities = processEntities(document.entities);
    }
  } else {
    // Check if there's a change in the relevant document properties
    const isDocumentChanged =
      document.latestWorkflowRun !== activeDocument.latestWorkflowRun ||
      document.docTypeId !== activeDocument.docTypeId ||
      document.docSubtypeId !== activeDocument.docSubtypeId ||
      document.entities !== activeDocument.entities;

    if (isDocumentChanged && document.entities) {
      document.entities = processEntities(document.entities);
    }
  }

  return document;
};

export const mapDocument = (document: any): DocumentDetails => {
  // Convert keys to camel case
  const mapped = camelcaseKeys(document, { deep: true }) as DocumentDetails;

  // Process entities if present
  if (document.entities) {
    mapped.entities = Object.entries(document.entities).map(([k, v]) => ({
      uuid: k,
      ...(v as object),
    })) as DocumentEntity[];
  }

  // Process approval checks if present, considering both object and array types
  if (
    typeof mapped.approvalChecks === 'object' &&
    !Array.isArray(mapped.approvalChecks) &&
    mapped.approvalChecks
  ) {
    mapped.approvalChecks = Object.entries(mapped.approvalChecks).map(([k, v]) => ({
      id: k,
      ...(v as object),
    })) as DocumentApprovalCheck[];
  } else if (mapped.approvalChecks) {
    mapped.approvalChecks = mapped.approvalChecks.map((a) => ({
      ...a,
      id: snakeToCamelCase(a.id),
    }));
  }
  if (mapped.initialApprovalChecks) {
    mapped.initialApprovalChecks = mapped.initialApprovalChecks.map((a) => ({
      ...a,
      id: snakeToCamelCase(a.id),
    }));
  }
  if (mapped.latestWorkflowRun?.timeProvisioned) {
    mapped.latestWorkflowRun = {
      ...mapped.latestWorkflowRun,
      timeProvisioned: document.latest_workflow_run?.time_provisioned.toDate(),
    };
  }
  if (document.topology?.parts) {
    const mappedParts = Object.entries(document.topology.parts)
      .map(
        ([k, v]) =>
          ({
            id: k,
            ...(camelcaseKeys(v, { deep: true }) as object),
          }) as DocumentPart,
      )
      .sort((a, b) => {
        // Get maximum bundlePageNo for non-archived pages only
        const aMaxPage = a?.pages
          .filter((page) => !page.archived) // Exclude archived pages
          .reduce((acc, page) => Math.max(acc, page.bundlePageNo), 0);

        const bMaxPage = b?.pages
          .filter((page) => !page.archived) // Exclude archived pages
          .reduce((acc, page) => Math.max(acc, page.bundlePageNo), 0);

        return aMaxPage - bMaxPage;
      });
    mapped.topology.parts = mappedParts;
  } else {
    mapped.topology = null;
  }
  if (document.action) {
    mapped.action = {
      ...camelcaseKeys(document.action, { deep: true }),
      timestamp: document.action.timestamp?.toDate(),
    };
  }

  // Process notes if present, including handling timestamps
  if (document.notes) {
    mapped.notes = Object.entries(document.notes)
      .map(([k, v]) => {
        let timestamp: any;
        try {
          timestamp = v['timestamp']?.toDate();
        } catch {
          timestamp = v['timestamp'];
        }
        return camelcaseKeys({
          ...(v as any),
          id: k,
          timestamp: timestamp,
        }) as DocumentNote;
      })
      .sort((a, b) => b?.timestamp.getTime() - a?.timestamp.getTime());
  }

  // Process dates if present
  mapped.availableTime = document?.available_time?.toDate();
  mapped.lastOpenedDate = document?.last_opened_date?.toDate();
  mapped.lastUpdatedDate = document?.last_updated_date?.toDate();
  mapped.uploadTime = document?.upload_time?.toDate();
  mapped.classification = document?.classification
    ? camelcaseKeys(document?.classification, { deep: true })
    : null;

  return mapped;
};
let lastId;

export function processEntities(newDocument, existingEntities: DocumentEntity[], masterDataImportStatus) {
  const newEntities = newDocument.entities ?? [];
  const isNewDoc = lastId !== newDocument.id;

  if (existingEntities.length === 0 || isNewDoc) {
    lastId = newDocument.id;
    return newEntities;
  }
  if (masterDataImportStatus === 'importing') {
    return newEntities;
  }

  const checkedIds = [];
  newEntities.forEach((ne) => {
    const existingindex = existingEntities.findIndex((e) => e.uuid === ne.uuid);
    if (existingindex !== -1) {
      const existing = existingEntities[existingindex];
      if (existing.isPending) {
        if (existing.isPending === 'add') {
          const changed = { ...existing };
          delete changed.isPending;
          checkedIds.push(existing.uuid);
          existingEntities[existingindex] = changed;
        } else if (existing.isPending === 'edit') {
          const changed = { ...existing };
          delete changed.isPending;
          checkedIds.push(existing.uuid);
          existingEntities[existingindex] = changed;
        } else {
          checkedIds.push(existing.uuid);
        }
      } else {
        if (existing.valueLocations.length !== ne.valueLocations.length) {
          existingEntities[existingindex] = ne;
        }
        checkedIds.push(ne.uuid);
      }
    } else {
      checkedIds.push(ne.uuid);
    }
  });
  const filteredEntities = existingEntities.filter((e) => {
    return !(!checkedIds.includes(e.uuid) && e.isPending === 'delete');
  });
  newEntities.forEach((ne) => {
    const exists = filteredEntities.find((e) => e.uuid === ne.uuid);
    if (ne.source === 'masterdata' && !exists) {
      filteredEntities.push(ne);
    }
  });

  lastId = newDocument.id;
  return filteredEntities;
}

export const getCopy =
  (docId: string, parentDocId?: string): AppThunk =>
  (dispatch, getState) => {
    // Unsubscribe from the previous copy subscription if it exists
    const copySub = getState().subs.copySub;
    if (copySub) {
      copySub();
      dispatch(subsSlice.actions.setCopySub(null));
    }

    const tenantId = getState().tenant.tenantId;
    dispatch(documentSlice.actions.setSelectedCopyId(docId));

    // Define the document path, considering if it's a mutation
    let docPath = `tenants/${tenantId}/documents/${parentDocId ?? docId}`;
    if (parentDocId) {
      docPath += `/mutations/${docId}`;
    }

    const docRef = doc(db, docPath);

    // Subscribe to snapshot updates for the document, processing as needed
    unsubCopy = onSnapshot(docRef, async (res) => {
      const documentData = res.data();
      if (!documentData || !getState().document.activeDocument) return;

      const newDocument = mapDocument(documentData);
      const processedDoc = processDocument(documentData.metadata, res.id, newDocument, getState);
      processedDoc.parentDocId = parentDocId;

      const exDoc = getState().document.activeDocument;
      const ignoredKeys = ['breadcrumbs'];
      const keys = Object.keys(compareObjects(exDoc, processedDoc));
      if (keys.length > 0 && keys.every((key) => ignoredKeys.includes(key))) {
        return;
      }
      const existingEntities = cloneDeep(getState().labeler.documentEntities);
      const masterDataImportStatus = getState().document.masterDataImportStatus;
      const newEntities = processEntities(newDocument, existingEntities, masterDataImportStatus);
      dispatch(setSortedDocumentEntities(newEntities));

      const copyStructure = cloneDeep(getState().document.copyStructure);
      const copyItemIndex = copyStructure?.copyList?.findIndex((e) => e.id === res.id) ?? -1;
      if (copyItemIndex === -1) {
        copyStructure.originalDoc = processedDoc;
      }
      if (copyStructure?.copyList) {
        if (copyItemIndex === -1) {
          copyStructure.originalDoc = processedDoc;
        } else {
          copyStructure.copyList[copyItemIndex] = processedDoc;
        }
      }
      dispatch(documentSlice.actions.setCopyStructure(copyStructure));
      dispatch(documentSlice.actions.setActiveDocument(processedDoc));
    });

    // Update the copy subscription in the state
    dispatch(subsSlice.actions.setCopySub(unsubCopy));
    return unsubCopy;
  };

export const latestFirestoreEntities: DocumentEntity[] = null;

lastId = '';
export const getDocument =
  (docId: string): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().tenant.tenantId;
    dispatch(documentSlice.actions.setDoesNotExist(null));

    if (unsubDocument) unsubDocument();

    const docPath = `tenants/${tenantId}/documents/${docId}`;
    const docRef = doc(db, docPath);

    const unSubscribe = onSnapshot(
      docRef,
      async (res) => {
        const selectedCopyId = getState().document.selectedCopyId;
        if (!res.exists()) {
          dispatch(documentSlice.actions.setDoesNotExist({}));
          return;
        }
        const newDocument = mapDocument(res.data());
        if (!newDocument.metadata) {
          dispatch(documentSlice.actions.setDoesNotExist(newDocument));
          return;
        }
        const processedDoc = processDocument(res.data().metadata, res.id, newDocument, getState);
        const exDoc = getState().document.activeDocument;

        // Ignore changes to specific keys
        const ignoredKeys = ['breadcrumbs'];
        const keys = Object.keys(compareObjects(exDoc, processedDoc));
        if (keys.length > 0 && keys.every((key) => ignoredKeys.includes(key))) {
          return;
        }

        if (selectedCopyId == null || selectedCopyId === docId) {
          const existingEntities = cloneDeep(getState().labeler.documentEntities);
          const masterDataImportStatus = getState().document.masterDataImportStatus;
          const newEntities = processEntities(newDocument, existingEntities, masterDataImportStatus);
          dispatch(setSortedDocumentEntities(newEntities));

          dispatch(documentSlice.actions.setActiveDocument(processedDoc));
          dispatch(documentSlice.actions.setActiveDocumentSnapshot(res));
          dispatch(documentSlice.actions.setSelectedCopyId(processedDoc.id));
        }

        // Handle copy/split documents by fetching referenced documents
        let copyList = cloneDeep(getState().document.copyStructure)?.copyList ?? [];
        if (newDocument?.nMutations > 0) {
          copyList = await getMutations(newDocument, getState);
          dispatch(documentSlice.actions.setCopyStructure({ originalDoc: processedDoc, copyList }));
        } else {
          handleSingleOrMissingCopies(copyList, docId, processedDoc, dispatch);
        }

        dispatch(documentSlice.actions.setIsProcessing(false));
      },
      (err) => {
        console.log(err);
      },
    );

    unsubDocument = unSubscribe;
    dispatch(subsSlice.actions.setDocumentSub(unSubscribe));
    return unSubscribe;
  };

export const getMutations = async (parentDoc: DocumentDetails, getState: () => CombinedState<RootState>) => {
  const tenantId = getState().tenant.tenantId;
  const copyStructure = getState().document.copyStructure as CopyStructure;
  let copyList = [];

  const reference = collection(db, `tenants/${tenantId}/documents/${parentDoc.id}/mutations`);
  const filters = [];

  if (copyStructure?.copyList && copyStructure.copyList.length > 0) {
    copyList = cloneDeep(copyStructure?.copyList);

    filters.push(
      where(
        documentId(),
        'not-in',
        copyList.map((e) => e.id),
      ),
    );
  }
  const colQuery = query(reference, ...filters);

  return await getDocs(colQuery).then((res) => {
    const docs = res.docs;
    const cs = getState().document.copyStructure as CopyStructure;
    if (cs?.copyList) copyList = [...cs.copyList];

    docs.forEach((doc) => {
      const docData = doc.data();
      const document = mapDocument(docData);

      const processedDocument = processDocument(document.metadata, doc.id, document, getState);
      processedDocument.parentDocId = parentDoc.id;

      const existing = copyList.findIndex((e) => e.id === processedDocument.id);
      if (existing !== -1) {
        copyList[existing] = processedDocument;
      } else {
        copyList.push(processedDocument);
      }
    });
    copyList.sort((a, b) => a.uploadTime.getTime() - b.uploadTime.getTime());
    copyList = (copyList as DocumentDetails[]).filter((e) => e.parentDocId === parentDoc.id);

    return copyList;
  });
};

export const getRawPDF =
  (docId: string, inboxId: string, isMutation?: boolean, topologyId?: string): AppThunk =>
  async (_, getState) => {
    const copyStructure = getState().document.copyStructure;
    const b = await getUserToken();
    if (!b) return;
    let url = `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}`;
    if (copyStructure && isMutation) {
      const { originalDoc } = copyStructure;
      url += `/documents/${originalDoc.id}/mutations/${docId}`;
    } else {
      url += `/documents/${docId}`;
    }
    if (topologyId) {
      url += `/topology/parts/${topologyId}`;
    }

    url += '/raw';
    return await api.get(url, {
      responseType: 'blob',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${b}`,
      },
    });
  };
function handleSingleOrMissingCopies(copyList: any[], docId: string, processedDoc: any, dispatch: any) {
  if (copyList.length > 1) {
    if (copyList.findIndex((cp) => cp.id === docId) === -1) {
      dispatch(documentSlice.actions.setSelectedCopyId(processedDoc.id));
    }
  } else {
    dispatch(documentSlice.actions.setSelectedCopyId(processedDoc.id));
  }
  dispatch(documentSlice.actions.setCopyStructure({ originalDoc: processedDoc }));
}

function pFileReader(data: Blob) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onloadend = () => resolve(fr.result); // CHANGE to whatever function you want which would eventually call resolve
    fr.onerror = reject;
    fr.readAsDataURL(data);
  });
}

//TODO: Investigate useQuery => React-Query or RTK-Query
export const getEntityCropThumb = async (
  docId: string,
  inboxId: string,
  entity: DocumentEntity,
  documentDetails: DocumentDetails,
) => {
  const b = await getUserToken();

  if (!b) return;
  if (!documentDetails) return;

  // Normalizing the entity using its associated document details
  const normalizedEntity = normalizeEntity(entity, documentDetails);
  const loc = normalizedEntity.valueLocations[0];

  // Building the URL for the specific cropped thumbnail of the entity
  const url = `${import.meta.env.VITE_PAPERBOX_CDN_URL}/inboxes/${inboxId}/documents/${docId}/pages/${
    entity.pageNo
  }?x1=${loc.x1}&x2=${loc.x2}&y1=${loc.y1}&y2=${loc.y2}&w=150`;

  const e = await api.get(url, {
    responseType: 'blob',
    headers: {
      accept: 'image/*',
      authorization: `Bearer ${b}`,
    },
  });

  return e.data ? await pFileReader(e.data) : null;
};

let thumbController: AbortController;
const BATCH_SIZE = 10; // Thumbnails are fetched in batches

export const getPageImageThumbs =
  (docId: string, inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    console.log('Getting thumbs');
    const thumbsLoading = getState().document.isThumbsLoading;
    const activeDocument = getState().document.activeDocument;

    const userToken = await getUserToken();
    if (!userToken) return;
    if (!activeDocument) return;

    let pageCount = activeDocument.dimensions.length;
    pageCount = Math.min(pageCount, 50); // Limiting to 50 pages

    if (thumbsLoading && thumbController) {
      thumbController.abort(); // Abort previous thumb loading
    }
    thumbController = new AbortController();

    dispatch(documentSlice.actions.setIsThumbsLoading(true));

    // Reduce pages to bundlePageNo and filter out archived ones
    const reducedPageNos = [
      ...new Set(
        activeDocument.topology.parts.flatMap((part) => part.pages.map((page) => page.bundlePageNo)),
      ),
    ];

    // Function to process thumbnails in batches
    const fetchThumbnailsInBatches = async (pageNosBatch) => {
      const pageImagesMap = getState().document.pageImagesMap;

      const promiseList = pageNosBatch
        .map((pageNo) => {
          if (pageImagesMap[docId]?.[pageNo]?.thumbUrl) return null;

          const url = `${
            import.meta.env.VITE_PAPERBOX_CDN_URL
          }/inboxes/${inboxId}/documents/${docId}/pages/${pageNo}?w=490&q=30`;

          return api
            .get(url, {
              signal: thumbController.signal,
              responseType: 'blob',
              headers: {
                accept: 'image/*',
                authorization: `Bearer ${userToken}`,
              },
            })
            .then((res) => ({ res, pageNo }))
            .catch(() => null);
        })
        .filter(Boolean);

      const results = await Promise.allSettled(promiseList);
      results.forEach((result) => {
        if (result.status === 'fulfilled' && result.value?.res?.data) {
          const { res, pageNo } = result.value;
          const reader = new FileReader();
          reader.readAsDataURL(res.data); // Convert Blob to base64
          reader.onloadend = () => {
            const base64String = reader.result as string;
            dispatch(
              documentSlice.actions.setDocumentMapImage({
                docId,
                pageNo,
                thumbUrl: base64String,
              }),
            );
          };
        }
      });
    };

    // Process thumbnails in batches with limited concurrency
    for (let i = 0; i < reducedPageNos.length; i += BATCH_SIZE) {
      const pageNosBatch = reducedPageNos.slice(i, i + BATCH_SIZE);
      await fetchThumbnailsInBatches(pageNosBatch);
    }

    dispatch(documentSlice.actions.setIsThumbsLoading(false)); // All thumbs are loaded
  };

// let thumbController: AbortController;
// export const getPageImageThumbs =
//   (docId: string, inboxId: string): AppThunk =>
//   async (dispatch, getState) => {
//     console.log('Getting thumbs');
//     const thumbsLoading = getState().document.isThumbsLoading;
//     const activeDocument = getState().document.activeDocument;
//
//     const b = await getUserToken();
//     if (!b) return;
//     if (!activeDocument) return;
//     let pageCount = activeDocument.dimensions.length;
//     if (pageCount > 50) pageCount = 50; // Limiting the pageCount to a maximum of 50
//     const promiseList = [];
//     if (thumbsLoading && thumbController) {
//       thumbController.abort(); // Abort previous thumb loading if still in progress
//     }
//     thumbController = new AbortController();
//
//     dispatch(documentSlice.actions.setIsThumbsLoading(true));
//
//     // Creating promises to get the thumbnails for each page
//     //Reduce to get list of actual bundlePageNo which are present
//     const reducePages = activeDocument.topology.parts
//       .reduce((acc, part) => {
//         return [
//           ...acc,
//           ...part.pages.map((p) => {
//             if (!p.archived) return p.bundlePageNo;
//           }),
//         ];
//       }, [])
//       .filter((e) => !!e);
//     const set = new Set(reducePages);
//     const reducedPageNos = [...set];
//
//     reducedPageNos.forEach((pageNo) => {
//       const pageImagesMap = getState().document.pageImagesMap;
//       if (pageImagesMap[docId]?.[pageNo]?.thumbUrl) return;
//       const url = `${
//         import.meta.env.VITE_PAPERBOX_CDN_URL
//       }/inboxes/${inboxId}/documents/${docId}/pages/${pageNo}?w=490&q=30`;
//       promiseList.push(
//         api.get(url, {
//           signal: thumbController.signal,
//           responseType: 'blob',
//           headers: {
//             accept: 'image/*',
//             authorization: 'Bearer ' + b,
//           },
//         })
//       );
//     });
//     Promise.all(promiseList)
//       .then((result) => {
//         result.forEach((res) => {
//           if (!res.data) return;
//           const activeDocId = getState().document.activeDocId;
//           if (activeDocId !== docId) return;
//           const pageNo = parseInt(res.config.url.split('/pages/')[1].split('?')[0]);
//           const reader = new FileReader();
//           reader.readAsDataURL(res.data); // Convert Blob to base64 string
//           reader.onloadend = () => {
//             const base64String = reader.result as string;
//             dispatch(
//               documentSlice.actions.setDocumentMapImage({
//                 docId,
//                 pageNo,
//                 thumbUrl: base64String,
//               })
//             );
//             dispatch(documentSlice.actions.setIsThumbsLoading(false)); // Marking the thumbs loading as completed
//           };
//         });
//       })
//
//       .catch(() => {
//         dispatch(documentSlice.actions.setIsThumbsLoading(false)); // Marking the thumbs loading as completed
//       });
//   };

let pageRetryCount = 0;
let imageController: AbortController;
export const getPageImage =
  (docId: string, inboxId: string, pageNo: number): AppThunk =>
  async (dispatch, getState) => {
    const pageImagesMap = getState().document.pageImagesMap;
    if (pageImagesMap[docId]?.[pageNo]?.imageUrl) return;
    const imageLoading = getState().document.isImageLoading;
    const b = await getUserToken();
    if (!b) return;
    console.log('Getting image');

    if (imageLoading && imageController) {
      imageController.abort();
    }
    imageController = new AbortController();

    dispatch(documentSlice.actions.setIsImageLoading(true));
    const url = `${
      import.meta.env.VITE_PAPERBOX_CDN_URL
    }/inboxes/${inboxId}/documents/${docId}/pages/${pageNo}?w=2000`;
    await axios
      .get(url, {
        signal: imageController.signal,
        responseType: 'blob',
        headers: {
          accept: 'image/*',
          authorization: `Bearer ${b}`,
        },
      })
      .then((res) => {
        pageRetryCount = 0;
        const activeDocId = getState().document.activeDocId;
        if (activeDocId === docId) {
          const reader = new FileReader();
          reader.readAsDataURL(res.data); // Convert Blob to base64 string
          reader.onloadend = () => {
            const base64String = reader.result as string;

            dispatch(documentSlice.actions.setDocumentMapImage({ docId, pageNo, imageUrl: base64String }));
            dispatch(documentSlice.actions.setIsImageLoading(false));
          };
        }
      })
      .catch((err: AxiosError) => {
        if (pageRetryCount === 0 && (err?.response?.status === 503 || err?.response?.status === 500)) {
          pageRetryCount = 1;
          dispatch(getPageImage(docId, inboxId, pageNo));
          dispatch(documentSlice.actions.setIsImageLoading(false));
        }
      });
  };
let jsonController: AbortController;
export const getJSON =
  (docId: string, inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    const b = await getUserToken();

    if (!b) return;

    if (getState().document.isJsonLoading && jsonController) {
      jsonController.abort();
    }
    jsonController = new AbortController();

    dispatch(documentSlice.actions.setIsJsonLoading(true));

    await api
      .get(`${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/${docId}?type_=json`, {
        signal: jsonController.signal,
        headers: {
          authorization: `Bearer ${b}`,
        },
      })
      .then((res) => dispatch(documentSlice.actions.setDocumentJSON(res.data)))
      .catch(() => dispatch(documentSlice.actions.setIsJsonLoading(false)));
  };
const categoryColors = ['#0085FF', '#6600ff', '#1ecea3', '#ff8000', '#c25596', '#43c9db'];

export const getAllowedFields =
  (docType: string): AppThunk =>
  (dispatch, getState) => {
    const docTypeSettings = getState().settings.docTypeSettings;
    const entityTypes = getState().settings.entityTypes;
    if (docTypeSettings.length > 0 && entityTypes.length > 0) {
      const settings = docTypeSettings.filter((item) => item.docTypeId === docType);
      if (settings.length > 0) {
        // Get DocType Categories
        const unMappedCategories = cloneDeep(settings[0].categories);
        if (unMappedCategories) {
          const mappedCategories = unMappedCategories.map((uc, i) => {
            const mappedEntityTypes = uc.entityTypes.map((et) => entityTypes.find((e) => e.id === et));
            return {
              ...uc,
              entityTypes: mappedEntityTypes,
              color: categoryColors[i % 6],
            } as DocTypeCategory;
          });

          dispatch(documentSlice.actions.setDocTypeCategories(mappedCategories));
        } else {
          dispatch(documentSlice.actions.setDocTypeCategories([]));
        }

        // Get Allowed Field Types
        const allowedFields = settings[0].settings.entityTypes;
        if (allowedFields.length === 0) {
          dispatch(documentSlice.actions.setAllowedEntityTypes([]));
        } else if (allowedFields.length > 0) {
          let mapped = allowedFields.map((e) => entityTypes.find((et) => et.id === e.id));
          mapped = mapped.filter((e) => e !== undefined);
          if (!isEqual(getState().document.allowedEntityTypes, mapped))
            dispatch(documentSlice.actions.setAllowedEntityTypes(mapped));
        }
      }
    }
  };
export type MasterDataSearchPayload = {
  prompt: string;
  fields: { prompt: string; reference?: string; reference_type?: string }[];
  table_ids?: string[];
};
export const searchMasterData =
  (payload: MasterDataSearchPayload, inboxId: string): AppThunk =>
  async (dispatch) => {
    const b = await getUserToken();
    if (!b) return;
    dispatch(documentSlice.actions.setMasterDataStatus('searching'));
    const url = `${import.meta.env.VITE_PAPERBOX_MASTERDATA_URL}/inboxes/${inboxId}/search`;
    api
      .post(url, payload, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${b}`,
        },
      })
      .then((res) => {
        const data = res.data as SearchResultsRaw;
        const mapped = camelcaseKeys(data, { deep: true });
        if (data.results.length === 0) {
          dispatch(documentSlice.actions.setMasterDataStatus('no-results'));
          dispatch(documentSlice.actions.setMasterDataResults(null));

          sleep(1000).then(() => dispatch(documentSlice.actions.setMasterDataStatus('idle')));
        } else {
          dispatch(documentSlice.actions.setMasterDataResults(mapped.results));
          dispatch(documentSlice.actions.setMasterDataStatus('idle'));
        }
      })
      .catch(() => {
        dispatch(documentSlice.actions.setMasterDataStatus('idle'));
      });
  };
export const importMasterDataResult =
  (inboxId: string, result: SearchResult, docId: string, mutationId?: string): AppThunk =>
  async (dispatch) => {
    const b = await getUserToken();
    if (!b) return;
    dispatch(documentSlice.actions.setMasterDataImportStatus('importing'));
    const mapped = snakecaseKeys(result, { deep: true });
    let url = `${import.meta.env.VITE_PAPERBOX_MASTERDATA_URL}/inboxes/${inboxId}/documents/${docId}`;
    if (mutationId) {
      url = `${url}/mutations/${mutationId}/import`;
    } else {
      url = `${url}/import`;
    }
    await api
      .post(url, mapped, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${b}`,
        },
      })
      .then(() => {
        dispatch(documentSlice.actions.setMasterDataImportStatus('idle'));

        dispatch(documentSlice.actions.setMasterDataResults(null));
      })
      .catch(() => {
        dispatch(documentSlice.actions.setMasterDataImportStatus('error'));
      });
  };

export const clearLock = (): AppThunk => (_, getState) => {
  const lockedId = getState().document.lockedId;
  const lockListener = getState().document.lockListener;
  const lockedList = getState().document.lockedList;
  const email = getState().user.userAccount.email;

  // Return if there's no locked list
  if (!lockedList || !lockedId) return;

  const locker = lockedList[lockedId];

  // If the locker exists and the email matches, clear the lock
  if (locker && locker.email === email) {
    const del = { [lockedId]: null };
    update(lockListener, del);
  }
};

export const clearDocumentSubs = (): AppThunk => (_, getState) => {
  const copySub = getState().subs.copySub;
  const documentSub = getState().subs.documentSub;

  if (copySub) copySub();
  if (documentSub) documentSub();
};

export let realtimeLockInterval: NodeJS.Timeout;
export const patchRealtimeLock =
  (documentId: string): AppThunk =>
  async (dispatch, getState) => {
    const userEmail = getState().user.userAccount.email;
    const lockListener = getState().document.lockListener;

    dispatch(documentSlice.actions.setLockedId(documentId));
    if (realtimeLockInterval) clearInterval(realtimeLockInterval);

    realtimeLockInterval = setInterval(() => {
      const up = {};
      up[documentId] = { email: userEmail, timestamp: new Date().toISOString() };
      update(lockListener, up);
    }, 30000);

    const del = {};
    del[documentId] = null;

    onDisconnect(lockListener)
      .update(del)
      .then(() => {
        runTransaction(child(lockListener, documentId), (data) => {
          return data ?? { email: userEmail, timestamp: new Date().toISOString() };
        });
      });
  };

export const createDocumentCopy =
  (docId: string, inboxId: string): AppThunk =>
  async (dispatch) => {
    dispatch(documentSlice.actions.setIsCreatingCopy(true));
    const b = await getUserToken();
    if (!b) return;
    return api
      .post(
        `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/${docId}/mutations`,
        null,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: `Bearer ${b}`,
          },
        },
      )
      .then((res) => {
        dispatch(documentSlice.actions.setIsCreatingCopy(false));
        return res;
      })
      .catch(() => {
        dispatch(documentSlice.actions.setIsCreatingCopy(false));
      });
  };

export const changeDocInbox =
  (docId: string, inboxId: string, destInboxId?: string): AppThunk =>
  async (dispatch) => {
    const b = await getUserToken();
    if (!b) return;
    dispatch(settingsSlice.actions.setDocTypeSettings([]));
    dispatch(documentSlice.actions.setActiveDocument(null));
    dispatch(documentSlice.actions.setCopyStructure(null));
    dispatch(inboxSlice.actions.setDocumentCounts(null));

    return api.put(
      `${
        import.meta.env.VITE_PAPERBOX_BACKEND_URL
      }/inboxes/${inboxId}/documents/${docId}?dest_inbox_id=${destInboxId}`,
      null,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${b}`,
        },
      },
    );
  };

export type DocumentNotePostPayload = {
  message: string;
};
export const postDocumentNote =
  (inboxId: string, docId: string, payload: DocumentNotePostPayload): AppThunk =>
  async (dispatch, getState) => {
    const activeDoc = cloneDeep(getState().document.activeDocument);
    const userId = getState().user.userAccount.id;

    const b = await getUserToken();
    if (!b) return;

    activeDoc.notes = [
      ...(activeDoc.notes ?? []),
      {
        id: 'pending',
        timestamp: new Date(),
        userId: userId,
        isLoading: true,
        message: payload.message,
      },
    ];
    dispatch(documentSlice.actions.setActiveDocument(activeDoc));
    return api.post(
      `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/${docId}/notes`,
      payload,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${b}`,
        },
      },
    );
  };

export const deleteDocumentNote =
  (inboxId: string, docId: string, noteId: string): AppThunk =>
  async (dispatch, getState) => {
    let activeDoc = cloneDeep(getState().document.activeDocument);
    const activeNotes = activeDoc.notes;

    const b = await getUserToken();
    if (!b) return;

    const activeNoteIndex = activeDoc.notes.findIndex((e) => e.id === noteId);

    activeNotes[activeNoteIndex] = { ...activeNotes[activeNoteIndex], isLoading: true };
    activeDoc = { ...activeDoc, notes: activeNotes };
    dispatch(documentSlice.actions.setActiveDocument(activeDoc));

    return api.delete(
      `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/${docId}/notes/${noteId}`,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${b}`,
        },
      },
    );
  };
export const getHistoricalSiblings =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    const {
      docTypeId,
      subTypeId,
      activeTagId,
      sortBy,
      isSortDescending,
      searchTerm,
      dateRange,
      action,
      approvalChecks,
    } = getState().inbox.documentListOptions;
    const activeDocument = getState().document.activeDocument;

    const b = await getUserToken();

    let sortByFiltered = sortBy;
    if (!elasticSortMap[sortBy]) sortByFiltered = 'actionDate';
    const params = {
      sort_by: elasticSortMap[sortByFiltered],
      sort_order: isSortDescending ? 'desc' : 'asc',
    };
    if (docTypeId) params['doc_type_id'] = docTypeId;
    if (subTypeId) params['doc_subtype_id'] = subTypeId;
    if (activeTagId) params['tag_type_id'] = activeTagId;
    if (action) params['action'] = action;
    if (searchTerm) {
      params['search_term'] = searchTerm;
      delete params['sort_by'];
      delete params['sort_order'];
    }
    if (dateRange) {
      params['start_date'] = dateRange[0].toISOString();
      params['end_date'] = dateRange[1].toISOString();
    }
    if (approvalChecks) {
      params['approval_checks'] = approvalChecks;
    }

    const res = await axios.get(
      `${import.meta.env.VITE_PAPERBOX_ANALYTICS_URL}/search/${inboxId}/${activeDocument.id}`,
      {
        params: params,
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${b}`,
        },
      },
    );

    if (res.data) {
      if (res.data.next) {
        dispatch(documentSlice.actions.setNextDocumentId(res.data.next));
      }
      if (res.data.previous) {
        dispatch(documentSlice.actions.setPrevDocumentId(res.data.previous));
      }
    }
  };
export const getPrevSiblingId =
  (inboxId: string, docTypeId: string): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().tenant.tenantId;
    const prevSiblingIdSub = getState().subs.prevSiblingIdSub;
    const currentSnapShot = getState().document.activeDocumentSnapshot;
    const activeDocument = getState().document.activeDocument;

    const { sortBy, isSortDescending, activeTagId, action } = getState().inbox.documentListOptions;
    const reference = collection(db, `tenants/${tenantId}/documents`);

    if (prevSiblingIdSub) prevSiblingIdSub();
    const filters: any[] = [
      where('active', '==', true),
      where('processed', '==', true),
      where('locker', '==', null),
    ];

    const sorting = getState().inbox.activeDocumentType;

    let sortingDocTypeId = sorting?.docTypeId;
    const sortingSubTypeId = sorting?.subTypeId;
    if (!activeDocument) return;

    if (sortingDocTypeId == null && docTypeId !== 'all') sortingDocTypeId = docTypeId;

    if (sortingDocTypeId != null && sortingDocTypeId !== '') {
      filters.push(where('doc_type_id', '==', sortingDocTypeId));
    }
    if (sortingSubTypeId != null) {
      filters.push(where('doc_subtype_id', '==', sortingSubTypeId));
    }
    if (activeTagId) {
      if (activeTagId === '@NO_TAG') {
        filters.push(where('tag_type_id', '==', null));
      } else {
        filters.push(where('tag_type_id', '==', activeTagId));
      }
    }
    if (action) {
      filters.push(where('action.type', '==', action));
    }
    filters.push(where('inbox_id', '==', inboxId));

    filters.push(
      orderBy(camelToSnakeCase(sortBy), !isSortDescending ? 'desc' : 'asc'),
      startAfter(currentSnapShot),
      limit(1),
    );

    const queryPrev = query(reference, ...filters);

    const unSubPrev = onSnapshot(queryPrev, (res) => {
      const doc = res.docs[0];
      if (doc) {
        dispatch(documentSlice.actions.setPrevDocumentId(doc.id));
      } else {
        dispatch(documentSlice.actions.setPrevDocumentId(null));
      }
    });
    dispatch(subsSlice.actions.setPrevSiblingIdSub(unSubPrev));

    return unSubPrev;
  };

export const getNextSiblingId =
  (inboxId: string, docTypeId: string): AppThunk =>
  (dispatch, getState) => {
    const nextSiblingIdSub = getState().subs.nextSiblingIdSub;
    const tenantId = getState().tenant.tenantId;
    const currentSnapShot = getState().document.activeDocumentSnapshot;
    const activeDocument = getState().document.activeDocument;
    const sorting = getState().inbox.activeDocumentType;
    const { sortBy, isSortDescending, activeTagId, action } = getState().inbox.documentListOptions;

    if (nextSiblingIdSub) nextSiblingIdSub();
    const reference = collection(db, `tenants/${tenantId}/documents`);

    const filters: any[] = [
      where('active', '==', true),
      where('processed', '==', true),
      where('locker', '==', null),
    ];

    let sortingDocTypeId = sorting?.docTypeId;
    const sortingSubTypeId = sorting?.subTypeId;
    if (!activeDocument) return;

    if (sortingDocTypeId == null && docTypeId !== 'all') sortingDocTypeId = docTypeId;

    if (sortingDocTypeId != null && sortingDocTypeId !== '') {
      filters.push(where('doc_type_id', '==', sortingDocTypeId));
    }
    if (sortingSubTypeId != null) {
      filters.push(where('doc_subtype_id', '==', sortingSubTypeId));
    }
    if (activeTagId) {
      if (activeTagId === '@NO_TAG') {
        filters.push(where('tag_type_id', '==', null));
      } else {
        filters.push(where('tag_type_id', '==', activeTagId));
      }
    }
    if (action) {
      filters.push(where('action.type', '==', action));
    }
    filters.push(where('inbox_id', '==', inboxId));

    filters.push(
      orderBy(camelToSnakeCase(sortBy), isSortDescending ? 'desc' : 'asc'),
      startAfter(currentSnapShot),
      limit(1),
    );

    const queryNext = query(reference, ...filters);

    const unSubNext = onSnapshot(queryNext, (res) => {
      const doc = res.docs[0];
      if (doc) {
        dispatch(documentSlice.actions.setNextDocumentId(doc.id));
      } else {
        dispatch(documentSlice.actions.setNextDocumentId(null));
      }
    });
    dispatch(subsSlice.actions.setNextSiblingIdSub(unSubNext));

    return unSubNext;
  };

export default documentSlice;
