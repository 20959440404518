import { sleep } from '@shared/helpers/helpers.ts';
import { useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin.module.scss';
import { ReactComponent as ConnectorBrioIcon } from '@svg/connector-brio.svg';
import { ReactComponent as ConnectorM365Icon } from '@svg/connector-ms365.svg';
import { ReactComponent as ConnectorRestIcon } from '@svg/connector-rest.svg';
import { ReactComponent as ConnectorFTPIcon } from '@svg/connector-sftp.svg';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router';

const AdminConnectors: React.FC = () => {
  const connectors = useSelector((state) => state.admin.connectors) ?? [];
  const sortedConnectors = [...connectors].sort((a, b) => a.type.localeCompare(b.type));
  const connectorRef = useRef();
  const { connectorId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (connectors.length > 0 && connectorId !== 'new') {
      if (connectorRef.current) {
        const activeConnectorItem = connectorRef.current as HTMLDivElement;
        sleep(1).then(() => {
          const sidebar = activeConnectorItem.closest('nav') as HTMLElement;
          if (sidebar) {
            sidebar.scrollTo({
              top: activeConnectorItem.offsetTop - sidebar.offsetTop,
              behavior: 'smooth',
            });
          }
        });
      }
    }
  }, [connectors, connectorId]);

  useEffect(() => {
    let timeout;
    if (!connectorId && sortedConnectors && sortedConnectors.length > 0) {
      navigate(`${sortedConnectors[0].id}`);
    } else if (connectorId && connectorId !== 'new' && sortedConnectors && sortedConnectors.length > 0) {
      const con = sortedConnectors.find((connector) => connector.id === connectorId);
      if (!con) {
        navigate('/admin/connectors/new');
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [connectorId, navigate, sortedConnectors]);

  return (
    <div className={s.subcontent}>
      <div className={s.subsidebar}>
        <div className={s.header}> Connectors</div>
        <NavLink data-testid={'connector-add'} to={'new'} className={s.add}>
          + {t('admin:connectors.addConnector')}
        </NavLink>
        <nav className={clsx(s.section)}>
          {!sortedConnectors && (
            <Skeleton
              count={5}
              height={20}
              width={'calc(100% - 32px '}
              style={{ marginBottom: 28, marginLeft: 16 }}
            />
          )}
          {sortedConnectors?.map((connector, i) => {
            let ret = (
              <NavLink
                data-testid={'connector-item'}
                ref={connectorId === connector.id ? connectorRef : null}
                key={connector.id}
                to={`${connector.id}`}
                className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
              >
                {connector.name}
                {connector.type === 'sftp' && <ConnectorFTPIcon />}
                {connector.type === 'http' && <ConnectorRestIcon />}
                {connector.type === 'ms365' && <ConnectorM365Icon />}
                {connector.type === 'portimabrio' && <ConnectorBrioIcon />}
              </NavLink>
            );
            if (i !== 0 && connector.type !== sortedConnectors[i - 1].type) {
              ret = (
                <>
                  <div style={{ width: '100%', height: 1, background: '#eeeeee', margin: '6px 0' }} /> {ret}
                </>
              );
            }
            return ret;
          })}
        </nav>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminConnectors;
