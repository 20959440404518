import FormBodyHeader from '@components/admin/components//form/FormBodyHeader.tsx';
import FormInputField from '@components/admin/components//form/FormInputField.tsx';
import FormMultiSelectField from '@components/admin/components//form/FormMultiSelectField.tsx';
import FormRequestField from '@components/admin/components//form/FormRequestField.tsx';
import FormSection from '@components/admin/components//form/FormSection.tsx';
import { MultiSelectDetailsType } from '@components/admin/components/AdminMultiSelectDialog.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import { DropdownOption } from '@components/shared/dropdown/StyledSelect.tsx';
import { IClientConnector, createEmptyRow } from '@shared/helpers/converters/connector.ts';
import {
  IClientEndpointBrio,
  IClientEndpointHTTP,
  endpointClientToRaw,
} from '@shared/helpers/converters/endpoint.ts';
import {
  IClientWebhook,
  IClientWebhookHTTP,
  webhookClientToRaw,
} from '@shared/helpers/converters/webhook.ts';
import { errorMap } from '@shared/helpers/helpers.ts';
import useChangeTracker, { ChangeSaveCallback } from '@shared/hooks/useChangeTracker.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import {
  clientConnectorsSelector,
  clientWebhookSelector,
  deleteEndpoint,
  deleteWebhook,
  getMasterdataTablesForInboxStatic,
  patchEndpoint,
  patchWebhook,
  postEndpoint,
  postWebhook,
} from '@shared/store/adminSlice.ts';
import { useSelector } from '@shared/store/store.ts';
import se from '@shared/styles/component/admin/admin-section.module.scss';
import { RaceBy } from '@uiball/loaders';
import { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import AdminBrioWebhookEdit from './AdminBrioWebhookEdit.tsx';

const AdminWebhookEdit: React.FC = () => {
  const clientWebhooks = useSelector(clientWebhookSelector);
  const clientConnectors = useSelector(clientConnectorsSelector);
  const inboxes = useSelector((state) => state.admin.inboxes);
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const webhooks = useSelector((state) => state.admin.webhooks);
  const sortedWebhooks = [...(webhooks ?? [])].sort((a, b) => a.name.localeCompare(b.name));

  const [activeBrioInboxId, setActiveBrioInboxId] = useState(null);
  const { webhookId } = useParams();
  const { t } = useTranslation();
  const { showDialog } = useModal();
  const navigate = useNavigate();

  const [tableData, setTableData] =
    useState<{ inboxId: string; data: { id: string; name: string }[] }[]>(null);

  const addRow = (type: 'headers' | 'payload' | 'params') => {
    setState((prevState: IClientWebhook) => {
      const copy = cloneDeep(prevState);
      const item = copy.endpoint[type] ?? [];
      copy.endpoint[type] = [...item, createEmptyRow()];
      return copy;
    });
  };

  const deleteRow = (index: number, type: 'headers' | 'payload' | 'params') => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      copy.endpoint[type][index] = { ...copy.endpoint[type][index], markedForDelete: true };
      return copy;
    });
  };

  const editRow = (
    index: number,
    target: 'key' | 'value' | 'valueType' | 'lock' | 'all',
    value: any,
    type: 'headers' | 'payload' | 'params',
  ) => {
    setState((fs) => {
      const copy = cloneDeep(fs);

      // Simplified the item assignment by utilizing the isAuth flag directly in the item assignment.
      const items = copy.endpoint[type] ?? [];
      const item = items[index];

      // Switch statement used to make the code more readable and easier to manage in the future.
      switch (target) {
        case 'value':
          item.value.content = value;
          break;
        case 'key': {
          const newValue = value.replaceAll(' ', '-');
          if (item.error && item.error.key === target && newValue.length >= item.key.length) {
            return copy;
          }
          item.error = undefined;
          // Nested objects validation only when the type is 'payload'.
          if (type === 'payload' && newValue.includes('__')) {
            validateNestedObjects(items, newValue, item);
          }
          item.key = newValue;
          break;
        }
        case 'valueType':
          item.value.type = value;
          item.tempLocked = value === '@PB_SECRET';
          if (value !== '@PB_SECRET') {
            item.locked = false;
          }
          break;
        case 'all':
          items[index] = value;
          break;
        default:
          item.tempLocked = value;
      }

      return copy;
    });
  };
  // Externalized validation logic to its own function for better readability and maintainability.
  const validateNestedObjects = (items: any[], value: string, item: any) => {
    const activeItems = items.filter((e) => !e.markedForDelete);
    const currentKeyGroups: string[] = value.split(/__/g);
    const sameParentKeyItems = activeItems.filter((e) => e?.key.includes(currentKeyGroups[0]));

    sameParentKeyItems.forEach((sameParentItem) => {
      const parentItemGroups = sameParentItem.key.split(/__/g);
      if (parentItemGroups.length === currentKeyGroups.length - 1) {
        const hasDirectParent = isEqual(
          parentItemGroups,
          currentKeyGroups.slice(0, currentKeyGroups.length - 1),
        );
        if (hasDirectParent) {
          item.error = {
            key: 'key',
            msg: 'Cannot create nested object while parent is already defined',
          };
        }
      }
    });
  };

  const handleSave: ChangeSaveCallback<IClientWebhook> = async () => {
    const rawWebhook = webhookClientToRaw(state);
    const rawEndpoint = endpointClientToRaw(state.endpoint);
    console.log(rawEndpoint);
    if (webhookId && initialState.endpoint?.id) {
      return patchWebhook(state.id, rawWebhook).then(() => {
        patchEndpoint(state.endpoint.id, rawEndpoint);
        navigate(`/admin/webhooks/${state.id}`);
      });
    }
    return postEndpoint(rawEndpoint).then(async (res) => {
      rawWebhook.endpoint_id = res.data.id;
      await postWebhook(rawWebhook).then((res) => {
        navigate(`/admin/webhooks/${res.data.id}`);
      });
    });
  };

  const initialState: IClientWebhook = useMemo(() => {
    if (webhookId !== 'new' && clientWebhooks && clientWebhooks.length > 0) {
      return clientWebhooks.find((e) => e.id === webhookId);
    }

    return {
      id: null,
      name: '',
      active: false,
      events: [],
      inboxes: [],
      actionTypes: [],
      endpoint: {
        id: null,
        name: '',
        type: 'http',
        connector: null,
        useDefaultPayload: true,
        endpointId: 'PostDocActBundleEndpoint',
        path: '',
        payload: [createEmptyRow()],
        headers: [createEmptyRow()],
        params: [createEmptyRow()],
        method: 'POST',
      },
    } as IClientWebhook;
  }, [webhookId, clientWebhooks]);

  const triggerOptions = useMemo(() => {
    return [
      { id: 'document.available', name: 'Document Available' },
      { id: 'document.action', name: 'Document Action' },
    ];
  }, []);

  const triggerActions = useMemo(() => {
    return [
      { id: 'approve', name: 'Approve' },
      { id: 'delete', name: 'Delete' },
      { id: 'bounce', name: 'Bounce' },
    ];
  }, []);

  const connectorOptions = useMemo(() => {
    if (clientConnectors && clientConnectors.length > 0) {
      return clientConnectors
        .filter((ct) => ct?.type === 'http' || ct?.type === 'portimabrio')
        .map((ct) => ({
          value: ct.id,
          label: ct.name,
          color: ct?.type === 'http' ? '#0085FF' : '#91C500',
          tag: {
            name: ct.type === 'http' ? 'HTTP' : 'Brio',
            value: ct.type,
          },
        })) as DropdownOption[];
    }
    return [];
  }, [clientConnectors]);

  const connectorDefault = useMemo(() => {
    if (connectorOptions && connectorOptions.length > 0) {
      if (initialState?.endpoint?.connector?.id) {
        return connectorOptions.find((e) => e.value === initialState?.endpoint.connector.id);
      }
      return connectorOptions[0];
    }
    return null;
  }, [initialState, connectorOptions]);

  const { save, saving, hasChanges, state, setState, error, handleInput } = useChangeTracker<IClientWebhook>(
    initialState,
    handleSave,
  );

  const handleDelete = async () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          deleteWebhook(webhookId).then(() => {
            deleteEndpoint(state.endpoint.id).then(() => {
              const filtered = sortedWebhooks.filter((e) => e.id !== webhookId);
              if (filtered && filtered.length > 0) {
                navigate(`/admin/webhooks/${filtered[0].id}`);
              } else {
                navigate('/admin/webhooks');
              }
            });
          });
        }}
        text={t('admin:webhooks.deleteDescription')}
      />,
    );
  };

  useEffect(() => {
    if (state?.endpoint?.connector?.type === 'portimabrio' && inboxes?.length > 0) {
      const promiseList = inboxes.map((inbox) =>
        getMasterdataTablesForInboxStatic(inbox.id, tenantId).then((response) => ({
          inboxId: inbox.id,
          data: response,
        })),
      );

      Promise.all(promiseList).then((res) => {
        setTableData(res);
      });
    }
  }, [inboxes, state, tenantId]);

  useEffect(() => {
    if (state?.endpoint?.connector?.type === 'portimabrio' && state?.inboxes) {
      const currentMapping = (state.endpoint as IClientEndpointBrio).inboxMapping || {};
      const newMapping = cloneDeep(currentMapping);

      // Add new inboxes to the mapping if they’re not already present
      state.inboxes.forEach((inbox) => {
        if (!newMapping[inbox.id]) {
          newMapping[inbox.id] = {
            doctype_settings: {},
            metadata_field_hierarchy: { claim: undefined, contract: undefined, party: undefined },
            metadata_field_mapping: { activity: {}, document: {} },
            activity_settings: { recipient_default_service: undefined },
          };
        }
      });

      // Remove inboxes from mapping if they’re not in state.inboxes
      Object.keys(newMapping).forEach((key) => {
        if (!state.inboxes.some((inbox) => inbox.id === key)) {
          delete newMapping[key];
        }
      });

      // Only update state if the new mapping differs from the current mapping
      if (!isEqual(newMapping, currentMapping)) {
        setState((fs) => {
          const updatedState = cloneDeep(fs);
          (updatedState.endpoint as IClientEndpointBrio).inboxMapping = newMapping;
          return updatedState;
        });
      }
    }
  }, [state.endpoint, state.inboxes, setState]);

  const mappedInboxes: any = useMemo(() => {
    if (state?.endpoint?.connector?.type === 'portimabrio' && tableData) {
      return inboxes.map((i) => {
        return {
          id: i.id,
          name: i.settings.name,
          children:
            tableData.find((e) => e.inboxId === i.id)?.data?.map((d) => ({ id: d.id, name: d.name })) || [],
        };
      });
    }
    return inboxes.map((i) => ({ id: i.id, name: i.settings.name }));
  }, [state, tableData, inboxes]);

  const handleDropdownChange = useCallback(
    (e) => {
      setState((fs) => {
        const changed = cloneDeep(fs);
        if (!fs || !connectorOptions?.length) return fs;
        if (!changed.endpoint) changed.endpoint = { path: '', connector: null, id: null };
        changed.endpoint.connector = clientConnectors.find((ct) => ct.id === e.value) as IClientConnector;
        return changed;
      });
    },
    [setState, connectorOptions?.length, clientConnectors],
  );
  if (webhookId !== 'new' && (state.id === '' || state.id === null || state === null))
    return (
      <div className={se.loading}>
        <RaceBy color={'#0085FF'} size={150} />
      </div>
    );

  return (
    <form onSubmit={save} className={se.form_body}>
      <FormBodyHeader
        errorMessage={errorMap[error] ?? null}
        // errorMessage={
        //   errorMap[error] ?? error ?? state?.endpoint?.connector?.type === 'portimabrio'
        //     ? t('admin:webhooks.BRIO_NOT_SUPPORTED')
        //     : null
        // }
        hasChanges={hasChanges}
        saving={saving}
        title={state?.name || t('admin:webhooks.add')}
      />
      <div className={se.sections}>
        <FormSection title={t('admin:webhooks.generalInfo')}>
          <FormInputField
            value={state?.active ?? true}
            type={'toggle'}
            label={t('admin:webhooks.enabled')}
            description={t('admin:webhooks.enabledDescription')}
            onChange={() => handleInput(!state?.active, 'active')}
          />
          <FormInputField
            required
            value={state?.name}
            type={'text'}
            label={t('admin:webhooks.name')}
            description={t('admin:webhooks.nameDescription')}
            onChange={(val) => handleInput(val, 'name')}
            placeholder={'Webhook Name'}
          />
          <FormInputField
            value={webhookId}
            hidden={webhookId === 'new'}
            type={'text'}
            label={t('admin:webhooks.id')}
            description={t('admin:webhooks.idDescription')}
            isPaperboxOnly
            isCopyField
          />
        </FormSection>

        <FormSection title={t('admin:webhooks.connection')}>
          <FormInputField
            hidden={!connectorOptions?.length}
            value={connectorOptions?.find((e) => e.value === state?.endpoint?.connector?.id)}
            label={t('admin:webhooks.connector')}
            description={t('admin:webhooks.connectorDescription')}
            type="dropdown"
            dropdownOptions={connectorOptions}
            defaultDropdownOption={connectorDefault}
            onChange={handleDropdownChange}
          />
          {state?.endpoint?.connector?.type === 'portimabrio' && (
            <FormInputField
              value={(state?.endpoint as IClientEndpointBrio)?.endpointId}
              type="text"
              label={t('admin:webhooks.brioEndpointId')}
              description={t('admin:webhooks.brioEndpointIdDescription')}
              onChange={(val) =>
                setState((fs) => {
                  const copy = cloneDeep(fs);
                  (copy.endpoint as IClientEndpointBrio) = val;
                  return copy;
                })
              }
              placeholder={'Endpoint ID'}
              disabled
            />
          )}
          {state?.endpoint?.connector?.type === 'http' && (
            <FormInputField
              value={(state?.endpoint as IClientEndpointHTTP)?.path}
              type="text"
              label={t('admin:webhooks.url')}
              description={t('admin:webhooks.urlDescription')}
              onChange={(val) =>
                setState((fs) => {
                  let newVal = val;
                  const copy = cloneDeep(fs) as IClientWebhookHTTP;
                  if (newVal[0] !== '/') newVal = `/${newVal}`;
                  (copy.endpoint as IClientEndpointHTTP).path = newVal.replace(' ', '-');
                  return copy;
                })
              }
              placeholder={'/path/example'}
            />
          )}
        </FormSection>
        <FormSection title={t('admin:webhooks.triggers')}>
          <FormMultiSelectField
            title={t('admin:webhooks.triggerEvent')}
            description={t('admin:webhooks.triggerEventDescription')}
            value={state?.events.map((e) => ({ id: e }))}
            onChange={(newValue) => setState({ ...state, events: newValue.map((e) => e.id) })}
            options={triggerOptions}
            showDialog={showDialog}
          />
          <FormMultiSelectField
            title={t('admin:webhooks.triggerInboxes')}
            description={t('admin:webhooks.triggerInboxesDescription')}
            value={state?.inboxes.map((e) => ({ id: e.id, children: e.tableIds }))}
            onChange={(newValue: MultiSelectDetailsType[]) => {
              setState({
                ...state,
                inboxes: newValue.map((e) => ({ id: e.id, tableIds: e.children as any })),
              });
            }}
            options={mappedInboxes}
            showDialog={showDialog}
          />
          {state?.events?.includes('document.action') && (
            <FormMultiSelectField
              title={t('admin:webhooks.triggerActionTypes')}
              description={t('admin:webhooks.triggerActionTypesDescription')}
              value={state?.actionTypes.map((e) => ({ id: e }))}
              onChange={(newValue) => setState({ ...state, actionTypes: newValue.map((e) => e.id) })}
              options={triggerActions}
              showDialog={showDialog}
            />
          )}
        </FormSection>
        {state?.endpoint?.connector?.type === 'portimabrio' && (
          <FormSection title={'Trigger Inbox Configuration'} noStyle>
            {(state.endpoint as IClientEndpointBrio).inboxMapping &&
              Object.entries((state.endpoint as IClientEndpointBrio).inboxMapping).map(([k, v]) => {
                const inboxDetails = inboxes.find((e) => e.id === k);
                return (
                  <AdminBrioWebhookEdit
                    key={k}
                    data={v}
                    isOpen={activeBrioInboxId === k}
                    inboxId={k}
                    inbox={inboxDetails}
                    setIsOpen={(bo) => setActiveBrioInboxId(bo ? k : '')}
                    onChange={(data) => {
                      setState((fs) => {
                        const copy = cloneDeep(fs) as any;
                        copy.endpoint.inboxMapping[k] = data;
                        return copy;
                      });
                    }}
                  />
                );
              })}
          </FormSection>
        )}
        {state?.endpoint?.connector?.type === 'http' && (
          <FormSection title={t('admin:webhooks.dataConfig')}>
            <FormRequestField
              testId={'data-params'}
              editRow={(index, t, v) => editRow(index, t, v, 'params')}
              description={t('admin:webhooks.queryParamsDescription')}
              label={t('admin:webhooks.queryParams')}
              deleteRow={(index) => deleteRow(index, 'params')}
              addRow={() => addRow('params')}
              items={(state?.endpoint as IClientEndpointHTTP)?.params}
            />

            <FormRequestField
              testId={'data-headers'}
              editRow={(index, t, v) => editRow(index, t, v, 'headers')}
              description={t('admin:webhooks.headersDescription')}
              label={t('admin:webhooks.headers')}
              deleteRow={(index) => deleteRow(index, 'headers')}
              addRow={() => addRow('headers')}
              items={(state?.endpoint as IClientEndpointHTTP).headers}
            />
            <FormInputField
              type={'toggle'}
              value={(state?.endpoint as IClientEndpointHTTP).useDefaultPayload ?? true}
              label={t('admin:webhooks.defaultPayload')}
              description={t('admin:webhooks.defaultPayloadDescription')}
              onChange={() => {
                setState((fs) => {
                  const copy = cloneDeep(fs) as any;
                  copy.endpoint.useDefaultPayload = !(state?.endpoint as IClientEndpointHTTP)
                    .useDefaultPayload;
                  return copy;
                });
              }}
            />
            <FormRequestField
              testId={'data-payload'}
              hidden={(state?.endpoint as IClientEndpointHTTP)?.useDefaultPayload}
              editRow={(index, t, v) => editRow(index, t, v, 'payload')}
              description={t('admin:webhooks.customPayloadDescription')}
              label={t('admin:webhooks.customPayload')}
              deleteRow={(index) => deleteRow(index, 'payload')}
              addRow={() => addRow('payload')}
              items={(state?.endpoint as IClientEndpointHTTP)?.payload}
            />
            {/*<FormInputField*/}
            {/*  hidden={state?.endpoint.useDefaultPayload }*/}
            {/*  value={ true}*/}
            {/*  // value={state?.endpoint.unFlatten ?? true}*/}
            {/*  type={'toggle'}*/}
            {/*  label={t('admin:webhooks.unflatten')}*/}
            {/*  description={t('admin:webhooks.unflattenDescription')}*/}
            {/*  onChange={() => {*/}
            {/*    setState((fs) => {*/}
            {/*      const copy = cloneDeep(fs);*/}
            {/*      copy.endpoint.unFlatten = !state?.endpoint.unFlatten;*/}
            {/*      return copy;*/}
            {/*    });*/}
            {/*  }}*/}
            {/*/>*/}
          </FormSection>
        )}
        <div>
          {webhookId !== 'new' && (
            <FormSection hidden={webhookId === 'new'} title={t('admin:webhooks.dangerZone')}>
              <FormInputField
                testId={'webhook-delete'}
                type={'button'}
                buttonOptions={{
                  type: 'error',
                  text: t('admin:webhooks.delete'),
                  onClick: handleDelete,
                }}
                label={t('admin:webhooks.delete')}
                description={t('admin:webhooks.deleteDescription')}
              />
            </FormSection>
          )}
        </div>
      </div>
    </form>
  );
};

export default AdminWebhookEdit;
